<template>

  <div>


    <vs-list v-if="allConsents && allConsents.length > 0 && mode === 'view'">
      <vs-list-header icon-pack="feather" icon="icon-pen-tool" title="Soglasja prostovoljca"></vs-list-header>
      <vs-list-item v-for="(item, index) in allConsents" :key="'consent_' + index" icon-pack="feather" color="success" :title="item.usersData ? 'Soglasje podeljeno/posodobljeno: ' + moment(item.usersData.updated_at).format('DD. MM. YYYY, HH:mm') : 'Soglasje ni podeljeno!'" :subtitle="item.description">
        <template slot="avatar" >
          <vs-avatar v-if="item.usersData" size="small" class="status mr-5" color="success" icon="check_circle" />
          <vs-avatar v-if="!item.usersData" size="small" class="status mr-5" color="danger" icon="radio_button_unchecked"/>
        </template>
      </vs-list-item>
    </vs-list>

    <div v-if="allConsents && allConsents.length > 0 && mode === 'edit'">

      <div v-for="(item, index) in allConsents" :key="'consent_' + index" class="my-5">

        <div class="flex mb-3">
          <vs-alert color="danger" icon-pack="feather" icon="icon-info" v-if="item.required">
            <span>To soglasje mora biti nujno podano!</span>
          </vs-alert>
          <vs-alert color="primary" icon-pack="feather" icon="icon-info" v-if="!item.required">
            <span>Sprejem tega soglasja ni obvezen!</span>
          </vs-alert>
        </div>

        <div class="flex">
          <div class="w-1/4" style="align-items: center; justify-content: center; display: flex;">
              <vs-switch class="ml-auto mr-auto" color="success" :key="item.id" v-model="item.acceptedByGiver" :disabled="item.acceptedByGiver && item.required === 1" @click.stop.prevent="detectChange(index)" v-if="item">
                <span slot="on">DA</span>
                <span slot="off">NE</span>
              </vs-switch>
          </div>

          <div class="w-3/4 ml-5">
            {{item.description}}
            <div>
              <small v-if="item.usersData" class="text-success">Datum zadnje spremembe: <b>{{moment(item.usersData.updated_at).format('DD. MM. YYYY, HH:mm')}}</b></small>
              <small v-if="!item.usersData"><em>Soglasje še ni bilo podano.</em></small>
            </div>
          </div>
        </div>

        <div class="flex mt-5">
          <div class="w-full m-2 bg-primary-gradient" style="height: 1px; overflow: hidden; opacity: 0.3"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue';



  export default {
    name: 'consents-widget',
    props: {
      consentGiver: String,
      userId: Number,
      mode: String
    },
    components: {

    },

    data() {
      return {
        allConsents: null,
        usersConsents: null,
      }
    },


    watch: {
      userId: {
        handler(val, oldVal) {
          console.log("userId CHANGE!!!!");

          // if (val) {
          //   this.getCompany();
          // }
        }
      },
      consentGiver: {
        handler(val, oldVal) {
          console.log("consentGiver CHANGE!!!!");

          // if (val) {
          //   this.getCompany();
          // }
        }
      },
    },

    computed: {

    },

    mounted() {
      const _this = this;
      _this.loadConsentsData();
    },

    destroyed() {
      console.log("destroyed");
      const _this = this;

    },

    beforeDestroy() {
      console.log("beforeDestroy");
      const _this = this;


    },

    methods: {
      loadConsentsData() {
        const _this = this;

        _this.getAllConsents().then(()=>{
          _this.getUsersConsents();
        });
      },

      async getAllConsents() {
        const _this = this;

        _this.$vs.loading();


        await Vue.prototype.$http.get(Vue.prototype.$config.api.productionNew + 'consent-management')
          .then((res) => {

            _this.allConsents =  _.filter(res.data.data, {consent_giver_type: _this.consentGiver} );
            _.each(_this.allConsents, function (val, key) {
              val.acceptedByGiver = false;
            });

            console.log("_this.allConsents: ", _this.allConsents);

            _this.$vs.loading.close()
          })
          .catch((error) => {
            console.log("error", error);
            _this.$vs.loading.close();
            return false
          });

      },

      async getUsersConsents() {
        const _this = this;

        _this.$vs.loading();


        await Vue.prototype.$http.get(Vue.prototype.$config.api.productionNew + 'consent-management/consents?consent_giver_type=' + _this.consentGiver + '&consent_giver_id=' + _this.userId)
          .then((res) => {

            _this.usersConsents = res.data.data;

            _.each(res.data.data, function (val, key) {

              let thisConsentIndexInAllConsents = _.findIndex(_this.allConsents, {consent_name: val.consent_name});

              if(thisConsentIndexInAllConsents > -1) {
                _this.allConsents[thisConsentIndexInAllConsents].usersData = val;
                _this.allConsents[thisConsentIndexInAllConsents].acceptedByGiver = true;
              }

            });
            _this.$forceUpdate();
            console.log("_this.allConsents: ", _this.allConsents);

            _this.$vs.loading.close()
          })
          .catch((error) => {
            console.log("error", error);
            _this.$vs.loading.close();
            return false
          });

      },

      getUnitDataById(unitId) {
        const _this = this;

        if (localStorage.getItem('userInfo')) {
          let unit = _.filter(JSON.parse(localStorage.getItem('userInfo')).units, {id: unitId})[0];
          if (unit) {
            return unit.name + ' (' + unit.coveredMunicipalities + ')';
          } else
            return 'podatka ni bilo mogoče pridobiti';
        }

      },

      stopTheConsent: (event) => {
        event.stopPropagation();
        event.preventDefault();
      },

      detectChange(consentIndex) {
        const _this = this;

        _this.$vs.loading();

        _this.allConsents[consentIndex].acceptedByGiver = _this.allConsents[consentIndex].acceptedByGiver === 1 ? 0 : 1;

        let consentData = {
          "consent_giver_type": _this.consentGiver,
          "consent_giver_id": _this.userId,
          "consent_name": _this.allConsents[consentIndex].consent_name,
          "consent_key": _this.allConsents[consentIndex].consent_key
        };

        Vue.prototype.$http.post(Vue.prototype.$config.api.productionNew + 'consent-management/consents', consentData)
          .then((res) => {

            console.log("RES!!!!!", res)
            _this.loadConsentsData();

          })
          .catch((error) => {
            console.log("error", error);
            _this.$vs.loading.close();
            return false
          });

        _this.$forceUpdate();
      }


    }
  }
</script>

