<template>
  <vs-alert
    v-if="volunteersWithUpcomingBirthdays && volunteersWithUpcomingBirthdays.length > 0"
    class="mt-5 mb-5"
    color="primary"
    :title="'Prostovoljci, ki so ali bodo v kratkem praznovali svoj rojstni dan'"
    active="true">
    <div v-for="(item, idx) in volunteersWithUpcomingBirthdays" :key="item.volunteer.id">
      {{idx+1}}. {{item.volunteer.first_name}} {{item.volunteer.last_name}}, {{moment(item.volunteer.birthday).format('DD. MM.')}}
    </div>
  </vs-alert>
</template>

<script>
import Vue from 'vue';
import DirectionsRenderer from "../../../helpers/DirectionsRenderer";
import ConsentsWidget from "../consentManagement/ConsentWidget";

export default {
  name: 'UpcomingBirthdays',

  components: {

  },
  props: {
    volunteersData: Array
  },
  data() {
    return {
      volunteersWithUpcomingBirthdays: [],
    }
  },

  computed: {},
  watch: {
    volunteersData() {
      this.checkForBirthdays();
    }
  },
  mounted() {
    const _this = this;
    _this.checkForBirthdays();
  },
  methods: {
    checkForBirthdays() {
      const _this = this;

      const startDate = _this.moment(new Date()).subtract(2, 'days').format('MM.DD.');
      const endDate = _this.moment(new Date()).add(5, 'days').format('MM.DD.');

      _.each(_this.volunteersData, function (val){
        let birthday = _this.moment(val.birthday).format('MM.DD.');

        if (_this.moment(birthday).isBetween(startDate, endDate)) {
          let today = _this.moment().format("YYYY-MM-DD");
          let age = _this.moment(today).diff(_this.moment(val.birthday).format('YYYY-MM-DD'), 'years');
          _this.moment(age).format("YYYY-MM-DD");

          _this.volunteersWithUpcomingBirthdays.push({
            volunteer: val,
            age: (age + 1),
          });

        }
      });
    }
  }
}
</script>
