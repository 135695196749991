export const slovenianHolidays = [
  {
    "DATUM": "1.01.2020",
    "IME_PRAZNIKA": "novo leto",
    "DAN_V_TEDNU": "sreda",
    "DELA_PROST_DAN": "da",
    "DAN": "1",
    "MESEC": "1",
    "LETO": "2020"
  },
  {
    "DATUM": "2.01.2020",
    "IME_PRAZNIKA": "novo leto",
    "DAN_V_TEDNU": "četrtek",
    "DELA_PROST_DAN": "da",
    "DAN": "2",
    "MESEC": "1",
    "LETO": "2020"
  },
  {
    "DATUM": "8.02.2020",
    "IME_PRAZNIKA": "Prešernov dan, slovenski kulturni praznik",
    "DAN_V_TEDNU": "sobota",
    "DELA_PROST_DAN": "da",
    "DAN": "8",
    "MESEC": "2",
    "LETO": "2020"
  },
  {
    "DATUM": "12.04.2020",
    "IME_PRAZNIKA": "velika noč",
    "DAN_V_TEDNU": "nedelja",
    "DELA_PROST_DAN": "da",
    "DAN": "12",
    "MESEC": "4",
    "LETO": "2020"
  },
  {
    "DATUM": "13.04.2020",
    "IME_PRAZNIKA": "velikonočni ponedeljek",
    "DAN_V_TEDNU": "ponedeljek",
    "DELA_PROST_DAN": "da",
    "DAN": "13",
    "MESEC": "4",
    "LETO": "2020"
  },
  {
    "DATUM": "27.04.2020",
    "IME_PRAZNIKA": "dan boja proti okupatorju",
    "DAN_V_TEDNU": "ponedeljek",
    "DELA_PROST_DAN": "da",
    "DAN": "27",
    "MESEC": "4",
    "LETO": "2020"
  },
  {
    "DATUM": "1.05.2020",
    "IME_PRAZNIKA": "praznik dela",
    "DAN_V_TEDNU": "petek",
    "DELA_PROST_DAN": "da",
    "DAN": "1",
    "MESEC": "5",
    "LETO": "2020"
  },
  {
    "DATUM": "2.05.2020",
    "IME_PRAZNIKA": "praznik dela",
    "DAN_V_TEDNU": "sobota",
    "DELA_PROST_DAN": "da",
    "DAN": "2",
    "MESEC": "5",
    "LETO": "2020"
  },
  {
    "DATUM": "31.05.2020",
    "IME_PRAZNIKA": "binkoštna nedelja",
    "DAN_V_TEDNU": "nedelja",
    "DELA_PROST_DAN": "da",
    "DAN": "31",
    "MESEC": "5",
    "LETO": "2020"
  },
  {
    "DATUM": "8.06.2020",
    "IME_PRAZNIKA": "dan Primoža Trubarja",
    "DAN_V_TEDNU": "ponedeljek",
    "DELA_PROST_DAN": "ne",
    "DAN": "8",
    "MESEC": "6",
    "LETO": "2020"
  },
  {
    "DATUM": "25.06.2020",
    "IME_PRAZNIKA": "dan državnosti",
    "DAN_V_TEDNU": "četrtek",
    "DELA_PROST_DAN": "da",
    "DAN": "25",
    "MESEC": "6",
    "LETO": "2020"
  },
  {
    "DATUM": "15.08.2020",
    "IME_PRAZNIKA": "Marijino vnebovzetje",
    "DAN_V_TEDNU": "sobota",
    "DELA_PROST_DAN": "da",
    "DAN": "15",
    "MESEC": "8",
    "LETO": "2020"
  },
  {
    "DATUM": "17.08.2020",
    "IME_PRAZNIKA": "združitev prekmurskih Slovencev z matičnim narodom",
    "DAN_V_TEDNU": "ponedeljek",
    "DELA_PROST_DAN": "ne",
    "DAN": "17",
    "MESEC": "8",
    "LETO": "2020"
  },
  {
    "DATUM": "15.09.2020",
    "IME_PRAZNIKA": "vrnitev Primorske k matični domovini",
    "DAN_V_TEDNU": "torek",
    "DELA_PROST_DAN": "ne",
    "DAN": "15",
    "MESEC": "9",
    "LETO": "2020"
  },
  {
    "DATUM": "25.10.2020",
    "IME_PRAZNIKA": "dan suverenosti",
    "DAN_V_TEDNU": "nedelja",
    "DELA_PROST_DAN": "ne",
    "DAN": "25",
    "MESEC": "10",
    "LETO": "2020"
  },
  {
    "DATUM": "31.10.2020",
    "IME_PRAZNIKA": "dan reformacije",
    "DAN_V_TEDNU": "sobota",
    "DELA_PROST_DAN": "da",
    "DAN": "31",
    "MESEC": "10",
    "LETO": "2020"
  },
  {
    "DATUM": "1.11.2020",
    "IME_PRAZNIKA": "dan spomina na mrtve",
    "DAN_V_TEDNU": "nedelja",
    "DELA_PROST_DAN": "da",
    "DAN": "1",
    "MESEC": "11",
    "LETO": "2020"
  },
  {
    "DATUM": "23.11.2020",
    "IME_PRAZNIKA": "dan Rudolfa Maistra",
    "DAN_V_TEDNU": "ponedeljek",
    "DELA_PROST_DAN": "ne",
    "DAN": "23",
    "MESEC": "11",
    "LETO": "2020"
  },
  {
    "DATUM": "25.12.2020",
    "IME_PRAZNIKA": "božič",
    "DAN_V_TEDNU": "petek",
    "DELA_PROST_DAN": "da",
    "DAN": "25",
    "MESEC": "12",
    "LETO": "2020"
  },
  {
    "DATUM": "26.12.2020",
    "IME_PRAZNIKA": "dan samostojnosti in enotnosti",
    "DAN_V_TEDNU": "sobota",
    "DELA_PROST_DAN": "da",
    "DAN": "26",
    "MESEC": "12",
    "LETO": "2020"
  },
  {
    "DATUM": "1.01.2021",
    "IME_PRAZNIKA": "novo leto",
    "DAN_V_TEDNU": "petek",
    "DELA_PROST_DAN": "da",
    "DAN": "1",
    "MESEC": "1",
    "LETO": "2021"
  },
  {
    "DATUM": "2.01.2021",
    "IME_PRAZNIKA": "novo leto",
    "DAN_V_TEDNU": "sobota",
    "DELA_PROST_DAN": "da",
    "DAN": "2",
    "MESEC": "1",
    "LETO": "2021"
  },
  {
    "DATUM": "8.02.2021",
    "IME_PRAZNIKA": "Prešernov dan, slovenski kulturni praznik",
    "DAN_V_TEDNU": "ponedeljek",
    "DELA_PROST_DAN": "da",
    "DAN": "8",
    "MESEC": "2",
    "LETO": "2021"
  },
  {
    "DATUM": "4.04.2021",
    "IME_PRAZNIKA": "velika noč",
    "DAN_V_TEDNU": "nedelja",
    "DELA_PROST_DAN": "da",
    "DAN": "4",
    "MESEC": "4",
    "LETO": "2021"
  },
  {
    "DATUM": "5.04.2021",
    "IME_PRAZNIKA": "velikonočni ponedeljek",
    "DAN_V_TEDNU": "ponedeljek",
    "DELA_PROST_DAN": "da",
    "DAN": "5",
    "MESEC": "4",
    "LETO": "2021"
  },
  {
    "DATUM": "27.04.2021",
    "IME_PRAZNIKA": "dan boja proti okupatorju",
    "DAN_V_TEDNU": "torek",
    "DELA_PROST_DAN": "da",
    "DAN": "27",
    "MESEC": "4",
    "LETO": "2021"
  },
  {
    "DATUM": "1.05.2021",
    "IME_PRAZNIKA": "praznik dela",
    "DAN_V_TEDNU": "sobota",
    "DELA_PROST_DAN": "da",
    "DAN": "1",
    "MESEC": "5",
    "LETO": "2021"
  },
  {
    "DATUM": "2.05.2021",
    "IME_PRAZNIKA": "praznik dela",
    "DAN_V_TEDNU": "nedelja",
    "DELA_PROST_DAN": "da",
    "DAN": "2",
    "MESEC": "5",
    "LETO": "2021"
  },
  {
    "DATUM": "23.05.2021",
    "IME_PRAZNIKA": "binkoštna nedelja",
    "DAN_V_TEDNU": "nedelja",
    "DELA_PROST_DAN": "da",
    "DAN": "23",
    "MESEC": "5",
    "LETO": "2021"
  },
  {
    "DATUM": "8.06.2021",
    "IME_PRAZNIKA": "dan Primoža Trubarja",
    "DAN_V_TEDNU": "torek",
    "DELA_PROST_DAN": "ne",
    "DAN": "8",
    "MESEC": "6",
    "LETO": "2021"
  },
  {
    "DATUM": "25.06.2021",
    "IME_PRAZNIKA": "dan državnosti",
    "DAN_V_TEDNU": "petek",
    "DELA_PROST_DAN": "da",
    "DAN": "25",
    "MESEC": "6",
    "LETO": "2021"
  },
  {
    "DATUM": "15.08.2021",
    "IME_PRAZNIKA": "Marijino vnebovzetje",
    "DAN_V_TEDNU": "nedelja",
    "DELA_PROST_DAN": "da",
    "DAN": "15",
    "MESEC": "8",
    "LETO": "2021"
  },
  {
    "DATUM": "17.08.2021",
    "IME_PRAZNIKA": "združitev prekmurskih Slovencev z matičnim narodom",
    "DAN_V_TEDNU": "torek",
    "DELA_PROST_DAN": "ne",
    "DAN": "17",
    "MESEC": "8",
    "LETO": "2021"
  },
  {
    "DATUM": "15.09.2021",
    "IME_PRAZNIKA": "vrnitev Primorske k matični domovini",
    "DAN_V_TEDNU": "sreda",
    "DELA_PROST_DAN": "ne",
    "DAN": "15",
    "MESEC": "9",
    "LETO": "2021"
  },
  {
    "DATUM": "25.10.2021",
    "IME_PRAZNIKA": "dan suverenosti",
    "DAN_V_TEDNU": "ponedeljek",
    "DELA_PROST_DAN": "ne",
    "DAN": "25",
    "MESEC": "10",
    "LETO": "2021"
  },
  {
    "DATUM": "31.10.2021",
    "IME_PRAZNIKA": "dan reformacije",
    "DAN_V_TEDNU": "nedelja",
    "DELA_PROST_DAN": "da",
    "DAN": "31",
    "MESEC": "10",
    "LETO": "2021"
  },
  {
    "DATUM": "1.11.2021",
    "IME_PRAZNIKA": "dan spomina na mrtve",
    "DAN_V_TEDNU": "ponedeljek",
    "DELA_PROST_DAN": "da",
    "DAN": "1",
    "MESEC": "11",
    "LETO": "2021"
  },
  {
    "DATUM": "23.11.2021",
    "IME_PRAZNIKA": "dan Rudolfa Maistra",
    "DAN_V_TEDNU": "torek",
    "DELA_PROST_DAN": "ne",
    "DAN": "23",
    "MESEC": "11",
    "LETO": "2021"
  },
  {
    "DATUM": "25.12.2021",
    "IME_PRAZNIKA": "božič",
    "DAN_V_TEDNU": "sobota",
    "DELA_PROST_DAN": "da",
    "DAN": "25",
    "MESEC": "12",
    "LETO": "2021"
  },
  {
    "DATUM": "26.12.2021",
    "IME_PRAZNIKA": "dan samostojnosti in enotnosti",
    "DAN_V_TEDNU": "nedelja",
    "DELA_PROST_DAN": "da",
    "DAN": "26",
    "MESEC": "12",
    "LETO": "2021"
  },
  {
    "DATUM": "1.01.2022",
    "IME_PRAZNIKA": "novo leto",
    "DAN_V_TEDNU": "sobota",
    "DELA_PROST_DAN": "da",
    "DAN": "1",
    "MESEC": "1",
    "LETO": "2022"
  },
  {
    "DATUM": "2.01.2022",
    "IME_PRAZNIKA": "novo leto",
    "DAN_V_TEDNU": "nedelja",
    "DELA_PROST_DAN": "da",
    "DAN": "2",
    "MESEC": "1",
    "LETO": "2022"
  },
  {
    "DATUM": "8.02.2022",
    "IME_PRAZNIKA": "Prešernov dan, slovenski kulturni praznik",
    "DAN_V_TEDNU": "torek",
    "DELA_PROST_DAN": "da",
    "DAN": "8",
    "MESEC": "2",
    "LETO": "2022"
  },
  {
    "DATUM": "17.04.2022",
    "IME_PRAZNIKA": "velika noč",
    "DAN_V_TEDNU": "nedelja",
    "DELA_PROST_DAN": "da",
    "DAN": "17",
    "MESEC": "4",
    "LETO": "2022"
  },
  {
    "DATUM": "18.04.2022",
    "IME_PRAZNIKA": "velikonočni ponedeljek",
    "DAN_V_TEDNU": "ponedeljek",
    "DELA_PROST_DAN": "da",
    "DAN": "18",
    "MESEC": "4",
    "LETO": "2022"
  },
  {
    "DATUM": "27.04.2022",
    "IME_PRAZNIKA": "dan boja proti okupatorju",
    "DAN_V_TEDNU": "sreda",
    "DELA_PROST_DAN": "da",
    "DAN": "27",
    "MESEC": "4",
    "LETO": "2022"
  },
  {
    "DATUM": "1.05.2022",
    "IME_PRAZNIKA": "praznik dela",
    "DAN_V_TEDNU": "nedelja",
    "DELA_PROST_DAN": "da",
    "DAN": "1",
    "MESEC": "5",
    "LETO": "2022"
  },
  {
    "DATUM": "2.05.2022",
    "IME_PRAZNIKA": "praznik dela",
    "DAN_V_TEDNU": "ponedeljek",
    "DELA_PROST_DAN": "da",
    "DAN": "2",
    "MESEC": "5",
    "LETO": "2022"
  },
  {
    "DATUM": "5.06.2022",
    "IME_PRAZNIKA": "binkoštna nedelja",
    "DAN_V_TEDNU": "nedelja",
    "DELA_PROST_DAN": "da",
    "DAN": "5",
    "MESEC": "6",
    "LETO": "2022"
  },
  {
    "DATUM": "8.06.2022",
    "IME_PRAZNIKA": "dan Primoža Trubarja",
    "DAN_V_TEDNU": "sreda",
    "DELA_PROST_DAN": "ne",
    "DAN": "8",
    "MESEC": "6",
    "LETO": "2022"
  },
  {
    "DATUM": "25.06.2022",
    "IME_PRAZNIKA": "dan državnosti",
    "DAN_V_TEDNU": "sobota",
    "DELA_PROST_DAN": "da",
    "DAN": "25",
    "MESEC": "6",
    "LETO": "2022"
  },
  {
    "DATUM": "15.08.2022",
    "IME_PRAZNIKA": "Marijino vnebovzetje",
    "DAN_V_TEDNU": "ponedeljek",
    "DELA_PROST_DAN": "da",
    "DAN": "15",
    "MESEC": "8",
    "LETO": "2022"
  },
  {
    "DATUM": "17.08.2022",
    "IME_PRAZNIKA": "združitev prekmurskih Slovencev z matičnim narodom",
    "DAN_V_TEDNU": "sreda",
    "DELA_PROST_DAN": "ne",
    "DAN": "17",
    "MESEC": "8",
    "LETO": "2022"
  },
  {
    "DATUM": "15.09.2022",
    "IME_PRAZNIKA": "vrnitev Primorske k matični domovini",
    "DAN_V_TEDNU": "četrtek",
    "DELA_PROST_DAN": "ne",
    "DAN": "15",
    "MESEC": "9",
    "LETO": "2022"
  },
  {
    "DATUM": "25.10.2022",
    "IME_PRAZNIKA": "dan suverenosti",
    "DAN_V_TEDNU": "torek",
    "DELA_PROST_DAN": "ne",
    "DAN": "25",
    "MESEC": "10",
    "LETO": "2022"
  },
  {
    "DATUM": "31.10.2022",
    "IME_PRAZNIKA": "dan reformacije",
    "DAN_V_TEDNU": "ponedeljek",
    "DELA_PROST_DAN": "da",
    "DAN": "31",
    "MESEC": "10",
    "LETO": "2022"
  },
  {
    "DATUM": "1.11.2022",
    "IME_PRAZNIKA": "dan spomina na mrtve",
    "DAN_V_TEDNU": "torek",
    "DELA_PROST_DAN": "da",
    "DAN": "1",
    "MESEC": "11",
    "LETO": "2022"
  },
  {
    "DATUM": "23.11.2022",
    "IME_PRAZNIKA": "dan Rudolfa Maistra",
    "DAN_V_TEDNU": "sreda",
    "DELA_PROST_DAN": "ne",
    "DAN": "23",
    "MESEC": "11",
    "LETO": "2022"
  },
  {
    "DATUM": "25.12.2022",
    "IME_PRAZNIKA": "božič",
    "DAN_V_TEDNU": "nedelja",
    "DELA_PROST_DAN": "da",
    "DAN": "25",
    "MESEC": "12",
    "LETO": "2022"
  },
  {
    "DATUM": "26.12.2022",
    "IME_PRAZNIKA": "dan samostojnosti in enotnosti",
    "DAN_V_TEDNU": "ponedeljek",
    "DELA_PROST_DAN": "da",
    "DAN": "26",
    "MESEC": "12",
    "LETO": "2022"
  },
  {
    "DATUM": "1.01.2023",
    "IME_PRAZNIKA": "novo leto",
    "DAN_V_TEDNU": "nedelja",
    "DELA_PROST_DAN": "da",
    "DAN": "1",
    "MESEC": "1",
    "LETO": "2023"
  },
  {
    "DATUM": "2.01.2023",
    "IME_PRAZNIKA": "novo leto",
    "DAN_V_TEDNU": "ponedeljek",
    "DELA_PROST_DAN": "da",
    "DAN": "2",
    "MESEC": "1",
    "LETO": "2023"
  },
  {
    "DATUM": "8.02.2023",
    "IME_PRAZNIKA": "Prešernov dan, slovenski kulturni praznik",
    "DAN_V_TEDNU": "sreda",
    "DELA_PROST_DAN": "da",
    "DAN": "8",
    "MESEC": "2",
    "LETO": "2023"
  },
  {
    "DATUM": "9.04.2023",
    "IME_PRAZNIKA": "velika noč",
    "DAN_V_TEDNU": "nedelja",
    "DELA_PROST_DAN": "da",
    "DAN": "9",
    "MESEC": "4",
    "LETO": "2023"
  },
  {
    "DATUM": "10.04.2023",
    "IME_PRAZNIKA": "velikonočni ponedeljek",
    "DAN_V_TEDNU": "ponedeljek",
    "DELA_PROST_DAN": "da",
    "DAN": "10",
    "MESEC": "4",
    "LETO": "2023"
  },
  {
    "DATUM": "27.04.2023",
    "IME_PRAZNIKA": "dan boja proti okupatorju",
    "DAN_V_TEDNU": "četrtek",
    "DELA_PROST_DAN": "da",
    "DAN": "27",
    "MESEC": "4",
    "LETO": "2023"
  },
  {
    "DATUM": "1.05.2023",
    "IME_PRAZNIKA": "praznik dela",
    "DAN_V_TEDNU": "ponedeljek",
    "DELA_PROST_DAN": "da",
    "DAN": "1",
    "MESEC": "5",
    "LETO": "2023"
  },
  {
    "DATUM": "2.05.2023",
    "IME_PRAZNIKA": "praznik dela",
    "DAN_V_TEDNU": "torek",
    "DELA_PROST_DAN": "da",
    "DAN": "2",
    "MESEC": "5",
    "LETO": "2023"
  },
  {
    "DATUM": "28.05.2023",
    "IME_PRAZNIKA": "binkoštna nedelja",
    "DAN_V_TEDNU": "nedelja",
    "DELA_PROST_DAN": "da",
    "DAN": "28",
    "MESEC": "5",
    "LETO": "2023"
  },
  {
    "DATUM": "8.06.2023",
    "IME_PRAZNIKA": "dan Primoža Trubarja",
    "DAN_V_TEDNU": "četrtek",
    "DELA_PROST_DAN": "ne",
    "DAN": "8",
    "MESEC": "6",
    "LETO": "2023"
  },
  {
    "DATUM": "25.06.2023",
    "IME_PRAZNIKA": "dan državnosti",
    "DAN_V_TEDNU": "nedelja",
    "DELA_PROST_DAN": "da",
    "DAN": "25",
    "MESEC": "6",
    "LETO": "2023"
  },
  {
    "DATUM": "15.08.2023",
    "IME_PRAZNIKA": "Marijino vnebovzetje",
    "DAN_V_TEDNU": "torek",
    "DELA_PROST_DAN": "da",
    "DAN": "15",
    "MESEC": "8",
    "LETO": "2023"
  },
  {
    "DATUM": "17.08.2023",
    "IME_PRAZNIKA": "združitev prekmurskih Slovencev z matičnim narodom",
    "DAN_V_TEDNU": "četrtek",
    "DELA_PROST_DAN": "ne",
    "DAN": "17",
    "MESEC": "8",
    "LETO": "2023"
  },
  {
    "DATUM": "15.09.2023",
    "IME_PRAZNIKA": "vrnitev Primorske k matični domovini",
    "DAN_V_TEDNU": "petek",
    "DELA_PROST_DAN": "ne",
    "DAN": "15",
    "MESEC": "9",
    "LETO": "2023"
  },
  {
    "DATUM": "25.10.2023",
    "IME_PRAZNIKA": "dan suverenosti",
    "DAN_V_TEDNU": "sreda",
    "DELA_PROST_DAN": "ne",
    "DAN": "25",
    "MESEC": "10",
    "LETO": "2023"
  },
  {
    "DATUM": "31.10.2023",
    "IME_PRAZNIKA": "dan reformacije",
    "DAN_V_TEDNU": "torek",
    "DELA_PROST_DAN": "da",
    "DAN": "31",
    "MESEC": "10",
    "LETO": "2023"
  },
  {
    "DATUM": "1.11.2023",
    "IME_PRAZNIKA": "dan spomina na mrtve",
    "DAN_V_TEDNU": "sreda",
    "DELA_PROST_DAN": "da",
    "DAN": "1",
    "MESEC": "11",
    "LETO": "2023"
  },
  {
    "DATUM": "23.11.2023",
    "IME_PRAZNIKA": "dan Rudolfa Maistra",
    "DAN_V_TEDNU": "četrtek",
    "DELA_PROST_DAN": "ne",
    "DAN": "23",
    "MESEC": "11",
    "LETO": "2023"
  },
  {
    "DATUM": "25.12.2023",
    "IME_PRAZNIKA": "božič",
    "DAN_V_TEDNU": "ponedeljek",
    "DELA_PROST_DAN": "da",
    "DAN": "25",
    "MESEC": "12",
    "LETO": "2023"
  },
  {
    "DATUM": "26.12.2023",
    "IME_PRAZNIKA": "dan samostojnosti in enotnosti",
    "DAN_V_TEDNU": "torek",
    "DELA_PROST_DAN": "da",
    "DAN": "26",
    "MESEC": "12",
    "LETO": "2023"
  },
  {
    "DATUM": "1.01.2024",
    "IME_PRAZNIKA": "novo leto",
    "DAN_V_TEDNU": "ponedeljek",
    "DELA_PROST_DAN": "da",
    "DAN": "1",
    "MESEC": "1",
    "LETO": "2024"
  },
  {
    "DATUM": "2.01.2024",
    "IME_PRAZNIKA": "novo leto",
    "DAN_V_TEDNU": "torek",
    "DELA_PROST_DAN": "da",
    "DAN": "2",
    "MESEC": "1",
    "LETO": "2024"
  },
  {
    "DATUM": "8.02.2024",
    "IME_PRAZNIKA": "Prešernov dan, slovenski kulturni praznik",
    "DAN_V_TEDNU": "četrtek",
    "DELA_PROST_DAN": "da",
    "DAN": "8",
    "MESEC": "2",
    "LETO": "2024"
  },
  {
    "DATUM": "31.03.2024",
    "IME_PRAZNIKA": "velika noč",
    "DAN_V_TEDNU": "nedelja",
    "DELA_PROST_DAN": "da",
    "DAN": "31",
    "MESEC": "3",
    "LETO": "2024"
  },
  {
    "DATUM": "1.04.2024",
    "IME_PRAZNIKA": "velikonočni ponedeljek",
    "DAN_V_TEDNU": "ponedeljek",
    "DELA_PROST_DAN": "da",
    "DAN": "1",
    "MESEC": "4",
    "LETO": "2024"
  },
  {
    "DATUM": "27.04.2024",
    "IME_PRAZNIKA": "dan boja proti okupatorju",
    "DAN_V_TEDNU": "sobota",
    "DELA_PROST_DAN": "da",
    "DAN": "27",
    "MESEC": "4",
    "LETO": "2024"
  },
  {
    "DATUM": "1.05.2024",
    "IME_PRAZNIKA": "praznik dela",
    "DAN_V_TEDNU": "sreda",
    "DELA_PROST_DAN": "da",
    "DAN": "1",
    "MESEC": "5",
    "LETO": "2024"
  },
  {
    "DATUM": "2.05.2024",
    "IME_PRAZNIKA": "praznik dela",
    "DAN_V_TEDNU": "četrtek",
    "DELA_PROST_DAN": "da",
    "DAN": "2",
    "MESEC": "5",
    "LETO": "2024"
  },
  {
    "DATUM": "19.05.2024",
    "IME_PRAZNIKA": "binkoštna nedelja",
    "DAN_V_TEDNU": "nedelja",
    "DELA_PROST_DAN": "da",
    "DAN": "19",
    "MESEC": "5",
    "LETO": "2024"
  },
  {
    "DATUM": "8.06.2024",
    "IME_PRAZNIKA": "dan Primoža Trubarja",
    "DAN_V_TEDNU": "sobota",
    "DELA_PROST_DAN": "ne",
    "DAN": "8",
    "MESEC": "6",
    "LETO": "2024"
  },
  {
    "DATUM": "25.06.2024",
    "IME_PRAZNIKA": "dan državnosti",
    "DAN_V_TEDNU": "torek",
    "DELA_PROST_DAN": "da",
    "DAN": "25",
    "MESEC": "6",
    "LETO": "2024"
  },
  {
    "DATUM": "15.08.2024",
    "IME_PRAZNIKA": "Marijino vnebovzetje",
    "DAN_V_TEDNU": "četrtek",
    "DELA_PROST_DAN": "da",
    "DAN": "15",
    "MESEC": "8",
    "LETO": "2024"
  },
  {
    "DATUM": "17.08.2024",
    "IME_PRAZNIKA": "združitev prekmurskih Slovencev z matičnim narodom",
    "DAN_V_TEDNU": "sobota",
    "DELA_PROST_DAN": "ne",
    "DAN": "17",
    "MESEC": "8",
    "LETO": "2024"
  },
  {
    "DATUM": "15.09.2024",
    "IME_PRAZNIKA": "vrnitev Primorske k matični domovini",
    "DAN_V_TEDNU": "nedelja",
    "DELA_PROST_DAN": "ne",
    "DAN": "15",
    "MESEC": "9",
    "LETO": "2024"
  },
  {
    "DATUM": "25.10.2024",
    "IME_PRAZNIKA": "dan suverenosti",
    "DAN_V_TEDNU": "petek",
    "DELA_PROST_DAN": "ne",
    "DAN": "25",
    "MESEC": "10",
    "LETO": "2024"
  },
  {
    "DATUM": "31.10.2024",
    "IME_PRAZNIKA": "dan reformacije",
    "DAN_V_TEDNU": "četrtek",
    "DELA_PROST_DAN": "da",
    "DAN": "31",
    "MESEC": "10",
    "LETO": "2024"
  },
  {
    "DATUM": "1.11.2024",
    "IME_PRAZNIKA": "dan spomina na mrtve",
    "DAN_V_TEDNU": "petek",
    "DELA_PROST_DAN": "da",
    "DAN": "1",
    "MESEC": "11",
    "LETO": "2024"
  },
  {
    "DATUM": "23.11.2024",
    "IME_PRAZNIKA": "dan Rudolfa Maistra",
    "DAN_V_TEDNU": "sobota",
    "DELA_PROST_DAN": "ne",
    "DAN": "23",
    "MESEC": "11",
    "LETO": "2024"
  },
  {
    "DATUM": "25.12.2024",
    "IME_PRAZNIKA": "božič",
    "DAN_V_TEDNU": "sreda",
    "DELA_PROST_DAN": "da",
    "DAN": "25",
    "MESEC": "12",
    "LETO": "2024"
  },
  {
    "DATUM": "26.12.2024",
    "IME_PRAZNIKA": "dan samostojnosti in enotnosti",
    "DAN_V_TEDNU": "četrtek",
    "DELA_PROST_DAN": "da",
    "DAN": "26",
    "MESEC": "12",
    "LETO": "2024"
  },
  {
    "DATUM": "1.01.2025",
    "IME_PRAZNIKA": "novo leto",
    "DAN_V_TEDNU": "sreda",
    "DELA_PROST_DAN": "da",
    "DAN": "1",
    "MESEC": "1",
    "LETO": "2025"
  },
  {
    "DATUM": "2.01.2025",
    "IME_PRAZNIKA": "novo leto",
    "DAN_V_TEDNU": "četrtek",
    "DELA_PROST_DAN": "da",
    "DAN": "2",
    "MESEC": "1",
    "LETO": "2025"
  },
  {
    "DATUM": "8.02.2025",
    "IME_PRAZNIKA": "Prešernov dan, slovenski kulturni praznik",
    "DAN_V_TEDNU": "sobota",
    "DELA_PROST_DAN": "da",
    "DAN": "8",
    "MESEC": "2",
    "LETO": "2025"
  },
  {
    "DATUM": "20.04.2025",
    "IME_PRAZNIKA": "velika noč",
    "DAN_V_TEDNU": "nedelja",
    "DELA_PROST_DAN": "da",
    "DAN": "20",
    "MESEC": "4",
    "LETO": "2025"
  },
  {
    "DATUM": "21.04.2025",
    "IME_PRAZNIKA": "velikonočni ponedeljek",
    "DAN_V_TEDNU": "ponedeljek",
    "DELA_PROST_DAN": "da",
    "DAN": "21",
    "MESEC": "4",
    "LETO": "2025"
  },
  {
    "DATUM": "27.04.2025",
    "IME_PRAZNIKA": "dan boja proti okupatorju",
    "DAN_V_TEDNU": "nedelja",
    "DELA_PROST_DAN": "da",
    "DAN": "27",
    "MESEC": "4",
    "LETO": "2025"
  },
  {
    "DATUM": "1.05.2025",
    "IME_PRAZNIKA": "praznik dela",
    "DAN_V_TEDNU": "četrtek",
    "DELA_PROST_DAN": "da",
    "DAN": "1",
    "MESEC": "5",
    "LETO": "2025"
  },
  {
    "DATUM": "2.05.2025",
    "IME_PRAZNIKA": "praznik dela",
    "DAN_V_TEDNU": "petek",
    "DELA_PROST_DAN": "da",
    "DAN": "2",
    "MESEC": "5",
    "LETO": "2025"
  },
  {
    "DATUM": "8.06.2025",
    "IME_PRAZNIKA": "binkoštna nedelja",
    "DAN_V_TEDNU": "nedelja",
    "DELA_PROST_DAN": "da",
    "DAN": "8",
    "MESEC": "6",
    "LETO": "2025"
  },
  {
    "DATUM": "8.06.2025",
    "IME_PRAZNIKA": "dan Primoža Trubarja",
    "DAN_V_TEDNU": "nedelja",
    "DELA_PROST_DAN": "ne",
    "DAN": "8",
    "MESEC": "6",
    "LETO": "2025"
  },
  {
    "DATUM": "25.06.2025",
    "IME_PRAZNIKA": "dan državnosti",
    "DAN_V_TEDNU": "sreda",
    "DELA_PROST_DAN": "da",
    "DAN": "25",
    "MESEC": "6",
    "LETO": "2025"
  },
  {
    "DATUM": "15.08.2025",
    "IME_PRAZNIKA": "Marijino vnebovzetje",
    "DAN_V_TEDNU": "petek",
    "DELA_PROST_DAN": "da",
    "DAN": "15",
    "MESEC": "8",
    "LETO": "2025"
  },
  {
    "DATUM": "17.08.2025",
    "IME_PRAZNIKA": "združitev prekmurskih Slovencev z matičnim narodom",
    "DAN_V_TEDNU": "nedelja",
    "DELA_PROST_DAN": "ne",
    "DAN": "17",
    "MESEC": "8",
    "LETO": "2025"
  },
  {
    "DATUM": "15.09.2025",
    "IME_PRAZNIKA": "vrnitev Primorske k matični domovini",
    "DAN_V_TEDNU": "ponedeljek",
    "DELA_PROST_DAN": "ne",
    "DAN": "15",
    "MESEC": "9",
    "LETO": "2025"
  },
  {
    "DATUM": "25.10.2025",
    "IME_PRAZNIKA": "dan suverenosti",
    "DAN_V_TEDNU": "sobota",
    "DELA_PROST_DAN": "ne",
    "DAN": "25",
    "MESEC": "10",
    "LETO": "2025"
  },
  {
    "DATUM": "31.10.2025",
    "IME_PRAZNIKA": "dan reformacije",
    "DAN_V_TEDNU": "petek",
    "DELA_PROST_DAN": "da",
    "DAN": "31",
    "MESEC": "10",
    "LETO": "2025"
  },
  {
    "DATUM": "1.11.2025",
    "IME_PRAZNIKA": "dan spomina na mrtve",
    "DAN_V_TEDNU": "sobota",
    "DELA_PROST_DAN": "da",
    "DAN": "1",
    "MESEC": "11",
    "LETO": "2025"
  },
  {
    "DATUM": "23.11.2025",
    "IME_PRAZNIKA": "dan Rudolfa Maistra",
    "DAN_V_TEDNU": "nedelja",
    "DELA_PROST_DAN": "ne",
    "DAN": "23",
    "MESEC": "11",
    "LETO": "2025"
  },
  {
    "DATUM": "25.12.2025",
    "IME_PRAZNIKA": "božič",
    "DAN_V_TEDNU": "četrtek",
    "DELA_PROST_DAN": "da",
    "DAN": "25",
    "MESEC": "12",
    "LETO": "2025"
  },
  {
    "DATUM": "26.12.2025",
    "IME_PRAZNIKA": "dan samostojnosti in enotnosti",
    "DAN_V_TEDNU": "petek",
    "DELA_PROST_DAN": "da",
    "DAN": "26",
    "MESEC": "12",
    "LETO": "2025"
  },
  {
    "DATUM": "1.01.2026",
    "IME_PRAZNIKA": "novo leto",
    "DAN_V_TEDNU": "četrtek",
    "DELA_PROST_DAN": "da",
    "DAN": "1",
    "MESEC": "1",
    "LETO": "2026"
  },
  {
    "DATUM": "2.01.2026",
    "IME_PRAZNIKA": "novo leto",
    "DAN_V_TEDNU": "petek",
    "DELA_PROST_DAN": "da",
    "DAN": "2",
    "MESEC": "1",
    "LETO": "2026"
  },
  {
    "DATUM": "8.02.2026",
    "IME_PRAZNIKA": "Prešernov dan, slovenski kulturni praznik",
    "DAN_V_TEDNU": "nedelja",
    "DELA_PROST_DAN": "da",
    "DAN": "8",
    "MESEC": "2",
    "LETO": "2026"
  },
  {
    "DATUM": "5.04.2026",
    "IME_PRAZNIKA": "velika noč",
    "DAN_V_TEDNU": "nedelja",
    "DELA_PROST_DAN": "da",
    "DAN": "5",
    "MESEC": "4",
    "LETO": "2026"
  },
  {
    "DATUM": "6.04.2026",
    "IME_PRAZNIKA": "velikonočni ponedeljek",
    "DAN_V_TEDNU": "ponedeljek",
    "DELA_PROST_DAN": "da",
    "DAN": "6",
    "MESEC": "4",
    "LETO": "2026"
  },
  {
    "DATUM": "27.04.2026",
    "IME_PRAZNIKA": "dan boja proti okupatorju",
    "DAN_V_TEDNU": "ponedeljek",
    "DELA_PROST_DAN": "da",
    "DAN": "27",
    "MESEC": "4",
    "LETO": "2026"
  },
  {
    "DATUM": "1.05.2026",
    "IME_PRAZNIKA": "praznik dela",
    "DAN_V_TEDNU": "petek",
    "DELA_PROST_DAN": "da",
    "DAN": "1",
    "MESEC": "5",
    "LETO": "2026"
  },
  {
    "DATUM": "2.05.2026",
    "IME_PRAZNIKA": "praznik dela",
    "DAN_V_TEDNU": "sobota",
    "DELA_PROST_DAN": "da",
    "DAN": "2",
    "MESEC": "5",
    "LETO": "2026"
  },
  {
    "DATUM": "24.05.2026",
    "IME_PRAZNIKA": "binkoštna nedelja",
    "DAN_V_TEDNU": "nedelja",
    "DELA_PROST_DAN": "da",
    "DAN": "24",
    "MESEC": "5",
    "LETO": "2026"
  },
  {
    "DATUM": "8.06.2026",
    "IME_PRAZNIKA": "dan Primoža Trubarja",
    "DAN_V_TEDNU": "ponedeljek",
    "DELA_PROST_DAN": "ne",
    "DAN": "8",
    "MESEC": "6",
    "LETO": "2026"
  },
  {
    "DATUM": "25.06.2026",
    "IME_PRAZNIKA": "dan državnosti",
    "DAN_V_TEDNU": "četrtek",
    "DELA_PROST_DAN": "da",
    "DAN": "25",
    "MESEC": "6",
    "LETO": "2026"
  },
  {
    "DATUM": "15.08.2026",
    "IME_PRAZNIKA": "Marijino vnebovzetje",
    "DAN_V_TEDNU": "sobota",
    "DELA_PROST_DAN": "da",
    "DAN": "15",
    "MESEC": "8",
    "LETO": "2026"
  },
  {
    "DATUM": "17.08.2026",
    "IME_PRAZNIKA": "združitev prekmurskih Slovencev z matičnim narodom",
    "DAN_V_TEDNU": "ponedeljek",
    "DELA_PROST_DAN": "ne",
    "DAN": "17",
    "MESEC": "8",
    "LETO": "2026"
  },
  {
    "DATUM": "15.09.2026",
    "IME_PRAZNIKA": "vrnitev Primorske k matični domovini",
    "DAN_V_TEDNU": "torek",
    "DELA_PROST_DAN": "ne",
    "DAN": "15",
    "MESEC": "9",
    "LETO": "2026"
  },
  {
    "DATUM": "25.10.2026",
    "IME_PRAZNIKA": "dan suverenosti",
    "DAN_V_TEDNU": "nedelja",
    "DELA_PROST_DAN": "ne",
    "DAN": "25",
    "MESEC": "10",
    "LETO": "2026"
  },
  {
    "DATUM": "31.10.2026",
    "IME_PRAZNIKA": "dan reformacije",
    "DAN_V_TEDNU": "sobota",
    "DELA_PROST_DAN": "da",
    "DAN": "31",
    "MESEC": "10",
    "LETO": "2026"
  },
  {
    "DATUM": "1.11.2026",
    "IME_PRAZNIKA": "dan spomina na mrtve",
    "DAN_V_TEDNU": "nedelja",
    "DELA_PROST_DAN": "da",
    "DAN": "1",
    "MESEC": "11",
    "LETO": "2026"
  },
  {
    "DATUM": "23.11.2026",
    "IME_PRAZNIKA": "dan Rudolfa Maistra",
    "DAN_V_TEDNU": "ponedeljek",
    "DELA_PROST_DAN": "ne",
    "DAN": "23",
    "MESEC": "11",
    "LETO": "2026"
  },
  {
    "DATUM": "25.12.2026",
    "IME_PRAZNIKA": "božič",
    "DAN_V_TEDNU": "petek",
    "DELA_PROST_DAN": "da",
    "DAN": "25",
    "MESEC": "12",
    "LETO": "2026"
  },
  {
    "DATUM": "26.12.2026",
    "IME_PRAZNIKA": "dan samostojnosti in enotnosti",
    "DAN_V_TEDNU": "sobota",
    "DELA_PROST_DAN": "da",
    "DAN": "26",
    "MESEC": "12",
    "LETO": "2026"
  },
  {
    "DATUM": "1.01.2027",
    "IME_PRAZNIKA": "novo leto",
    "DAN_V_TEDNU": "petek",
    "DELA_PROST_DAN": "da",
    "DAN": "1",
    "MESEC": "1",
    "LETO": "2027"
  },
  {
    "DATUM": "2.01.2027",
    "IME_PRAZNIKA": "novo leto",
    "DAN_V_TEDNU": "sobota",
    "DELA_PROST_DAN": "da",
    "DAN": "2",
    "MESEC": "1",
    "LETO": "2027"
  },
  {
    "DATUM": "8.02.2027",
    "IME_PRAZNIKA": "Prešernov dan, slovenski kulturni praznik",
    "DAN_V_TEDNU": "ponedeljek",
    "DELA_PROST_DAN": "da",
    "DAN": "8",
    "MESEC": "2",
    "LETO": "2027"
  },
  {
    "DATUM": "28.03.2027",
    "IME_PRAZNIKA": "velika noč",
    "DAN_V_TEDNU": "nedelja",
    "DELA_PROST_DAN": "da",
    "DAN": "28",
    "MESEC": "3",
    "LETO": "2027"
  },
  {
    "DATUM": "29.03.2027",
    "IME_PRAZNIKA": "velikonočni ponedeljek",
    "DAN_V_TEDNU": "ponedeljek",
    "DELA_PROST_DAN": "da",
    "DAN": "29",
    "MESEC": "3",
    "LETO": "2027"
  },
  {
    "DATUM": "27.04.2027",
    "IME_PRAZNIKA": "dan boja proti okupatorju",
    "DAN_V_TEDNU": "torek",
    "DELA_PROST_DAN": "da",
    "DAN": "27",
    "MESEC": "4",
    "LETO": "2027"
  },
  {
    "DATUM": "1.05.2027",
    "IME_PRAZNIKA": "praznik dela",
    "DAN_V_TEDNU": "sobota",
    "DELA_PROST_DAN": "da",
    "DAN": "1",
    "MESEC": "5",
    "LETO": "2027"
  },
  {
    "DATUM": "2.05.2027",
    "IME_PRAZNIKA": "praznik dela",
    "DAN_V_TEDNU": "nedelja",
    "DELA_PROST_DAN": "da",
    "DAN": "2",
    "MESEC": "5",
    "LETO": "2027"
  },
  {
    "DATUM": "16.05.2027",
    "IME_PRAZNIKA": "binkoštna nedelja",
    "DAN_V_TEDNU": "nedelja",
    "DELA_PROST_DAN": "da",
    "DAN": "16",
    "MESEC": "5",
    "LETO": "2027"
  },
  {
    "DATUM": "8.06.2027",
    "IME_PRAZNIKA": "dan Primoža Trubarja",
    "DAN_V_TEDNU": "torek",
    "DELA_PROST_DAN": "ne",
    "DAN": "8",
    "MESEC": "6",
    "LETO": "2027"
  },
  {
    "DATUM": "25.06.2027",
    "IME_PRAZNIKA": "dan državnosti",
    "DAN_V_TEDNU": "petek",
    "DELA_PROST_DAN": "da",
    "DAN": "25",
    "MESEC": "6",
    "LETO": "2027"
  },
  {
    "DATUM": "15.08.2027",
    "IME_PRAZNIKA": "Marijino vnebovzetje",
    "DAN_V_TEDNU": "nedelja",
    "DELA_PROST_DAN": "da",
    "DAN": "15",
    "MESEC": "8",
    "LETO": "2027"
  },
  {
    "DATUM": "17.08.2027",
    "IME_PRAZNIKA": "združitev prekmurskih Slovencev z matičnim narodom",
    "DAN_V_TEDNU": "torek",
    "DELA_PROST_DAN": "ne",
    "DAN": "17",
    "MESEC": "8",
    "LETO": "2027"
  },
  {
    "DATUM": "15.09.2027",
    "IME_PRAZNIKA": "vrnitev Primorske k matični domovini",
    "DAN_V_TEDNU": "sreda",
    "DELA_PROST_DAN": "ne",
    "DAN": "15",
    "MESEC": "9",
    "LETO": "2027"
  },
  {
    "DATUM": "25.10.2027",
    "IME_PRAZNIKA": "dan suverenosti",
    "DAN_V_TEDNU": "ponedeljek",
    "DELA_PROST_DAN": "ne",
    "DAN": "25",
    "MESEC": "10",
    "LETO": "2027"
  },
  {
    "DATUM": "31.10.2027",
    "IME_PRAZNIKA": "dan reformacije",
    "DAN_V_TEDNU": "nedelja",
    "DELA_PROST_DAN": "da",
    "DAN": "31",
    "MESEC": "10",
    "LETO": "2027"
  },
  {
    "DATUM": "1.11.2027",
    "IME_PRAZNIKA": "dan spomina na mrtve",
    "DAN_V_TEDNU": "ponedeljek",
    "DELA_PROST_DAN": "da",
    "DAN": "1",
    "MESEC": "11",
    "LETO": "2027"
  },
  {
    "DATUM": "23.11.2027",
    "IME_PRAZNIKA": "dan Rudolfa Maistra",
    "DAN_V_TEDNU": "torek",
    "DELA_PROST_DAN": "ne",
    "DAN": "23",
    "MESEC": "11",
    "LETO": "2027"
  },
  {
    "DATUM": "25.12.2027",
    "IME_PRAZNIKA": "božič",
    "DAN_V_TEDNU": "sobota",
    "DELA_PROST_DAN": "da",
    "DAN": "25",
    "MESEC": "12",
    "LETO": "2027"
  },
  {
    "DATUM": "26.12.2027",
    "IME_PRAZNIKA": "dan samostojnosti in enotnosti",
    "DAN_V_TEDNU": "nedelja",
    "DELA_PROST_DAN": "da",
    "DAN": "26",
    "MESEC": "12",
    "LETO": "2027"
  },
  {
    "DATUM": "1.01.2028",
    "IME_PRAZNIKA": "novo leto",
    "DAN_V_TEDNU": "sobota",
    "DELA_PROST_DAN": "da",
    "DAN": "1",
    "MESEC": "1",
    "LETO": "2028"
  },
  {
    "DATUM": "2.01.2028",
    "IME_PRAZNIKA": "novo leto",
    "DAN_V_TEDNU": "nedelja",
    "DELA_PROST_DAN": "da",
    "DAN": "2",
    "MESEC": "1",
    "LETO": "2028"
  },
  {
    "DATUM": "8.02.2028",
    "IME_PRAZNIKA": "Prešernov dan, slovenski kulturni praznik",
    "DAN_V_TEDNU": "torek",
    "DELA_PROST_DAN": "da",
    "DAN": "8",
    "MESEC": "2",
    "LETO": "2028"
  },
  {
    "DATUM": "16.04.2028",
    "IME_PRAZNIKA": "velika noč",
    "DAN_V_TEDNU": "nedelja",
    "DELA_PROST_DAN": "da",
    "DAN": "16",
    "MESEC": "4",
    "LETO": "2028"
  },
  {
    "DATUM": "17.04.2028",
    "IME_PRAZNIKA": "velikonočni ponedeljek",
    "DAN_V_TEDNU": "ponedeljek",
    "DELA_PROST_DAN": "da",
    "DAN": "17",
    "MESEC": "4",
    "LETO": "2028"
  },
  {
    "DATUM": "27.04.2028",
    "IME_PRAZNIKA": "dan boja proti okupatorju",
    "DAN_V_TEDNU": "četrtek",
    "DELA_PROST_DAN": "da",
    "DAN": "27",
    "MESEC": "4",
    "LETO": "2028"
  },
  {
    "DATUM": "1.05.2028",
    "IME_PRAZNIKA": "praznik dela",
    "DAN_V_TEDNU": "ponedeljek",
    "DELA_PROST_DAN": "da",
    "DAN": "1",
    "MESEC": "5",
    "LETO": "2028"
  },
  {
    "DATUM": "2.05.2028",
    "IME_PRAZNIKA": "praznik dela",
    "DAN_V_TEDNU": "torek",
    "DELA_PROST_DAN": "da",
    "DAN": "2",
    "MESEC": "5",
    "LETO": "2028"
  },
  {
    "DATUM": "4.06.2028",
    "IME_PRAZNIKA": "binkoštna nedelja",
    "DAN_V_TEDNU": "nedelja",
    "DELA_PROST_DAN": "da",
    "DAN": "4",
    "MESEC": "6",
    "LETO": "2028"
  },
  {
    "DATUM": "8.06.2028",
    "IME_PRAZNIKA": "dan Primoža Trubarja",
    "DAN_V_TEDNU": "četrtek",
    "DELA_PROST_DAN": "ne",
    "DAN": "8",
    "MESEC": "6",
    "LETO": "2028"
  },
  {
    "DATUM": "25.06.2028",
    "IME_PRAZNIKA": "dan državnosti",
    "DAN_V_TEDNU": "nedelja",
    "DELA_PROST_DAN": "da",
    "DAN": "25",
    "MESEC": "6",
    "LETO": "2028"
  },
  {
    "DATUM": "15.08.2028",
    "IME_PRAZNIKA": "Marijino vnebovzetje",
    "DAN_V_TEDNU": "torek",
    "DELA_PROST_DAN": "da",
    "DAN": "15",
    "MESEC": "8",
    "LETO": "2028"
  },
  {
    "DATUM": "17.08.2028",
    "IME_PRAZNIKA": "združitev prekmurskih Slovencev z matičnim narodom",
    "DAN_V_TEDNU": "četrtek",
    "DELA_PROST_DAN": "ne",
    "DAN": "17",
    "MESEC": "8",
    "LETO": "2028"
  },
  {
    "DATUM": "15.09.2028",
    "IME_PRAZNIKA": "vrnitev Primorske k matični domovini",
    "DAN_V_TEDNU": "petek",
    "DELA_PROST_DAN": "ne",
    "DAN": "15",
    "MESEC": "9",
    "LETO": "2028"
  },
  {
    "DATUM": "25.10.2028",
    "IME_PRAZNIKA": "dan suverenosti",
    "DAN_V_TEDNU": "sreda",
    "DELA_PROST_DAN": "ne",
    "DAN": "25",
    "MESEC": "10",
    "LETO": "2028"
  },
  {
    "DATUM": "31.10.2028",
    "IME_PRAZNIKA": "dan reformacije",
    "DAN_V_TEDNU": "torek",
    "DELA_PROST_DAN": "da",
    "DAN": "31",
    "MESEC": "10",
    "LETO": "2028"
  },
  {
    "DATUM": "1.11.2028",
    "IME_PRAZNIKA": "dan spomina na mrtve",
    "DAN_V_TEDNU": "sreda",
    "DELA_PROST_DAN": "da",
    "DAN": "1",
    "MESEC": "11",
    "LETO": "2028"
  },
  {
    "DATUM": "23.11.2028",
    "IME_PRAZNIKA": "dan Rudolfa Maistra",
    "DAN_V_TEDNU": "četrtek",
    "DELA_PROST_DAN": "ne",
    "DAN": "23",
    "MESEC": "11",
    "LETO": "2028"
  },
  {
    "DATUM": "25.12.2028",
    "IME_PRAZNIKA": "božič",
    "DAN_V_TEDNU": "ponedeljek",
    "DELA_PROST_DAN": "da",
    "DAN": "25",
    "MESEC": "12",
    "LETO": "2028"
  },
  {
    "DATUM": "26.12.2028",
    "IME_PRAZNIKA": "dan samostojnosti in enotnosti",
    "DAN_V_TEDNU": "torek",
    "DELA_PROST_DAN": "da",
    "DAN": "26",
    "MESEC": "12",
    "LETO": "2028"
  },
  {
    "DATUM": "1.01.2029",
    "IME_PRAZNIKA": "novo leto",
    "DAN_V_TEDNU": "ponedeljek",
    "DELA_PROST_DAN": "da",
    "DAN": "1",
    "MESEC": "1",
    "LETO": "2029"
  },
  {
    "DATUM": "2.01.2029",
    "IME_PRAZNIKA": "novo leto",
    "DAN_V_TEDNU": "torek",
    "DELA_PROST_DAN": "da",
    "DAN": "2",
    "MESEC": "1",
    "LETO": "2029"
  },
  {
    "DATUM": "8.02.2029",
    "IME_PRAZNIKA": "Prešernov dan, slovenski kulturni praznik",
    "DAN_V_TEDNU": "četrtek",
    "DELA_PROST_DAN": "da",
    "DAN": "8",
    "MESEC": "2",
    "LETO": "2029"
  },
  {
    "DATUM": "1.04.2029",
    "IME_PRAZNIKA": "velika noč",
    "DAN_V_TEDNU": "nedelja",
    "DELA_PROST_DAN": "da",
    "DAN": "1",
    "MESEC": "4",
    "LETO": "2029"
  },
  {
    "DATUM": "2.04.2029",
    "IME_PRAZNIKA": "velikonočni ponedeljek",
    "DAN_V_TEDNU": "ponedeljek",
    "DELA_PROST_DAN": "da",
    "DAN": "2",
    "MESEC": "4",
    "LETO": "2029"
  },
  {
    "DATUM": "27.04.2029",
    "IME_PRAZNIKA": "dan boja proti okupatorju",
    "DAN_V_TEDNU": "petek",
    "DELA_PROST_DAN": "da",
    "DAN": "27",
    "MESEC": "4",
    "LETO": "2029"
  },
  {
    "DATUM": "1.05.2029",
    "IME_PRAZNIKA": "praznik dela",
    "DAN_V_TEDNU": "torek",
    "DELA_PROST_DAN": "da",
    "DAN": "1",
    "MESEC": "5",
    "LETO": "2029"
  },
  {
    "DATUM": "2.05.2029",
    "IME_PRAZNIKA": "praznik dela",
    "DAN_V_TEDNU": "sreda",
    "DELA_PROST_DAN": "da",
    "DAN": "2",
    "MESEC": "5",
    "LETO": "2029"
  },
  {
    "DATUM": "20.05.2029",
    "IME_PRAZNIKA": "binkoštna nedelja",
    "DAN_V_TEDNU": "nedelja",
    "DELA_PROST_DAN": "da",
    "DAN": "20",
    "MESEC": "5",
    "LETO": "2029"
  },
  {
    "DATUM": "8.06.2029",
    "IME_PRAZNIKA": "dan Primoža Trubarja",
    "DAN_V_TEDNU": "petek",
    "DELA_PROST_DAN": "ne",
    "DAN": "8",
    "MESEC": "6",
    "LETO": "2029"
  },
  {
    "DATUM": "25.06.2029",
    "IME_PRAZNIKA": "dan državnosti",
    "DAN_V_TEDNU": "ponedeljek",
    "DELA_PROST_DAN": "da",
    "DAN": "25",
    "MESEC": "6",
    "LETO": "2029"
  },
  {
    "DATUM": "15.08.2029",
    "IME_PRAZNIKA": "Marijino vnebovzetje",
    "DAN_V_TEDNU": "sreda",
    "DELA_PROST_DAN": "da",
    "DAN": "15",
    "MESEC": "8",
    "LETO": "2029"
  },
  {
    "DATUM": "17.08.2029",
    "IME_PRAZNIKA": "združitev prekmurskih Slovencev z matičnim narodom",
    "DAN_V_TEDNU": "petek",
    "DELA_PROST_DAN": "ne",
    "DAN": "17",
    "MESEC": "8",
    "LETO": "2029"
  },
  {
    "DATUM": "15.09.2029",
    "IME_PRAZNIKA": "vrnitev Primorske k matični domovini",
    "DAN_V_TEDNU": "sobota",
    "DELA_PROST_DAN": "ne",
    "DAN": "15",
    "MESEC": "9",
    "LETO": "2029"
  },
  {
    "DATUM": "25.10.2029",
    "IME_PRAZNIKA": "dan suverenosti",
    "DAN_V_TEDNU": "četrtek",
    "DELA_PROST_DAN": "ne",
    "DAN": "25",
    "MESEC": "10",
    "LETO": "2029"
  },
  {
    "DATUM": "31.10.2029",
    "IME_PRAZNIKA": "dan reformacije",
    "DAN_V_TEDNU": "sreda",
    "DELA_PROST_DAN": "da",
    "DAN": "31",
    "MESEC": "10",
    "LETO": "2029"
  },
  {
    "DATUM": "1.11.2029",
    "IME_PRAZNIKA": "dan spomina na mrtve",
    "DAN_V_TEDNU": "četrtek",
    "DELA_PROST_DAN": "da",
    "DAN": "1",
    "MESEC": "11",
    "LETO": "2029"
  },
  {
    "DATUM": "23.11.2029",
    "IME_PRAZNIKA": "dan Rudolfa Maistra",
    "DAN_V_TEDNU": "petek",
    "DELA_PROST_DAN": "ne",
    "DAN": "23",
    "MESEC": "11",
    "LETO": "2029"
  },
  {
    "DATUM": "25.12.2029",
    "IME_PRAZNIKA": "božič",
    "DAN_V_TEDNU": "torek",
    "DELA_PROST_DAN": "da",
    "DAN": "25",
    "MESEC": "12",
    "LETO": "2029"
  },
  {
    "DATUM": "26.12.2029",
    "IME_PRAZNIKA": "dan samostojnosti in enotnosti",
    "DAN_V_TEDNU": "sreda",
    "DELA_PROST_DAN": "da",
    "DAN": "26",
    "MESEC": "12",
    "LETO": "2029"
  },
  {
    "DATUM": "1.01.2030",
    "IME_PRAZNIKA": "novo leto",
    "DAN_V_TEDNU": "torek",
    "DELA_PROST_DAN": "da",
    "DAN": "1",
    "MESEC": "1",
    "LETO": "2030"
  },
  {
    "DATUM": "2.01.2030",
    "IME_PRAZNIKA": "novo leto",
    "DAN_V_TEDNU": "sreda",
    "DELA_PROST_DAN": "da",
    "DAN": "2",
    "MESEC": "1",
    "LETO": "2030"
  },
  {
    "DATUM": "8.02.2030",
    "IME_PRAZNIKA": "Prešernov dan, slovenski kulturni praznik",
    "DAN_V_TEDNU": "petek",
    "DELA_PROST_DAN": "da",
    "DAN": "8",
    "MESEC": "2",
    "LETO": "2030"
  },
  {
    "DATUM": "21.04.2030",
    "IME_PRAZNIKA": "velika noč",
    "DAN_V_TEDNU": "nedelja",
    "DELA_PROST_DAN": "da",
    "DAN": "21",
    "MESEC": "4",
    "LETO": "2030"
  },
  {
    "DATUM": "22.04.2030",
    "IME_PRAZNIKA": "velikonočni ponedeljek",
    "DAN_V_TEDNU": "ponedeljek",
    "DELA_PROST_DAN": "da",
    "DAN": "22",
    "MESEC": "4",
    "LETO": "2030"
  },
  {
    "DATUM": "27.04.2030",
    "IME_PRAZNIKA": "dan boja proti okupatorju",
    "DAN_V_TEDNU": "sobota",
    "DELA_PROST_DAN": "da",
    "DAN": "27",
    "MESEC": "4",
    "LETO": "2030"
  },
  {
    "DATUM": "1.05.2030",
    "IME_PRAZNIKA": "praznik dela",
    "DAN_V_TEDNU": "sreda",
    "DELA_PROST_DAN": "da",
    "DAN": "1",
    "MESEC": "5",
    "LETO": "2030"
  },
  {
    "DATUM": "2.05.2030",
    "IME_PRAZNIKA": "praznik dela",
    "DAN_V_TEDNU": "četrtek",
    "DELA_PROST_DAN": "da",
    "DAN": "2",
    "MESEC": "5",
    "LETO": "2030"
  },
  {
    "DATUM": "8.06.2030",
    "IME_PRAZNIKA": "dan Primoža Trubarja",
    "DAN_V_TEDNU": "sobota",
    "DELA_PROST_DAN": "ne",
    "DAN": "8",
    "MESEC": "6",
    "LETO": "2030"
  },
  {
    "DATUM": "9.06.2030",
    "IME_PRAZNIKA": "binkoštna nedelja",
    "DAN_V_TEDNU": "nedelja",
    "DELA_PROST_DAN": "da",
    "DAN": "9",
    "MESEC": "6",
    "LETO": "2030"
  },
  {
    "DATUM": "25.06.2030",
    "IME_PRAZNIKA": "dan državnosti",
    "DAN_V_TEDNU": "torek",
    "DELA_PROST_DAN": "da",
    "DAN": "25",
    "MESEC": "6",
    "LETO": "2030"
  },
  {
    "DATUM": "15.08.2030",
    "IME_PRAZNIKA": "Marijino vnebovzetje",
    "DAN_V_TEDNU": "četrtek",
    "DELA_PROST_DAN": "da",
    "DAN": "15",
    "MESEC": "8",
    "LETO": "2030"
  },
  {
    "DATUM": "17.08.2030",
    "IME_PRAZNIKA": "združitev prekmurskih Slovencev z matičnim narodom",
    "DAN_V_TEDNU": "sobota",
    "DELA_PROST_DAN": "ne",
    "DAN": "17",
    "MESEC": "8",
    "LETO": "2030"
  },
  {
    "DATUM": "15.09.2030",
    "IME_PRAZNIKA": "vrnitev Primorske k matični domovini",
    "DAN_V_TEDNU": "nedelja",
    "DELA_PROST_DAN": "ne",
    "DAN": "15",
    "MESEC": "9",
    "LETO": "2030"
  },
  {
    "DATUM": "25.10.2030",
    "IME_PRAZNIKA": "dan suverenosti",
    "DAN_V_TEDNU": "petek",
    "DELA_PROST_DAN": "ne",
    "DAN": "25",
    "MESEC": "10",
    "LETO": "2030"
  },
  {
    "DATUM": "31.10.2030",
    "IME_PRAZNIKA": "dan reformacije",
    "DAN_V_TEDNU": "četrtek",
    "DELA_PROST_DAN": "da",
    "DAN": "31",
    "MESEC": "10",
    "LETO": "2030"
  },
  {
    "DATUM": "1.11.2030",
    "IME_PRAZNIKA": "dan spomina na mrtve",
    "DAN_V_TEDNU": "petek",
    "DELA_PROST_DAN": "da",
    "DAN": "1",
    "MESEC": "11",
    "LETO": "2030"
  },
  {
    "DATUM": "23.11.2030",
    "IME_PRAZNIKA": "dan Rudolfa Maistra",
    "DAN_V_TEDNU": "sobota",
    "DELA_PROST_DAN": "ne",
    "DAN": "23",
    "MESEC": "11",
    "LETO": "2030"
  },
  {
    "DATUM": "25.12.2030",
    "IME_PRAZNIKA": "božič",
    "DAN_V_TEDNU": "sreda",
    "DELA_PROST_DAN": "da",
    "DAN": "25",
    "MESEC": "12",
    "LETO": "2030"
  },
  {
    "DATUM": "26.12.2030",
    "IME_PRAZNIKA": "dan samostojnosti in enotnosti",
    "DAN_V_TEDNU": "četrtek",
    "DELA_PROST_DAN": "da",
    "DAN": "26",
    "MESEC": "12",
    "LETO": "2030"
  }
]
