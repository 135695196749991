<template>
  <div>

    <vs-button radius size="large" color="success" icon-pack="feather" icon="icon-plus-circle" id="addFabBtn"
               title="Dodaj novega uporabnika" @click.stop="addReservation()"></vs-button>

    <UpcomingBirthdays :volunteers-data="activeVolunteersData"></UpcomingBirthdays>

    <ReservationsCalendar ref="reservationCalendar"/>
  </div>
</template>
<script>
import Vue from "vue";
import ReservationsCalendar from "../components/Sopotniki/reservations/ReservationsCalendar";
import UpcomingBirthdays from "@/components/Sopotniki/volunteers/UpcomingBirthdays";

export default {
  components: {
    ReservationsCalendar,
    UpcomingBirthdays
  },

  data() {
    return {
      activeVolunteersData: null
    }
  },

  beforeRouteLeave(to, from, next) {
    console.log("beforeRouteLeave");
    const _this = this;

    let reservationCalendarComponent = this.$refs.reservationCalendar;
    reservationCalendarComponent.closeSideBar();

    setTimeout(() => {
      next()
    }, 100);
  },

  mounted() {
    this.getVolunteersForUnit();
  },

  methods: {
    addReservation() {
      const _this = this;

      _this.$router.push({name: 'Rezervacija'})
    },

    getVolunteersForUnit() {
      const _this = this;

      _this.$vs.loading();
      console.log("_this.$globalFunctions.getCurrentUnit().id", _this.$globalFunctions.getCurrentUnit().id);

      Vue.prototype.$http.get(Vue.prototype.$config.api.productionOld + 'volunteers/unit/' + _this.$globalFunctions.getCurrentUnit().id)
        .then((res) => {

          console.log("---->", res.data.data);

          _this.activeVolunteersData = _.filter(res.data.data, (volunteer) => volunteer.active === 1);

          _this.$vs.loading.close();
        })
        .catch((error) => {
          console.log("error", error);
          _this.$vs.loading.close();
          return false
        });
    },
  }


}
</script>
